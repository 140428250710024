.fitem-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 0 0 220px;
  padding: 4rem 3.2rem 2.4rem;
  border: 1px solid rgba(var(--color-lightpurple), 0.15);
  border-radius: 8px;
  box-shadow: 0 4px 24px rgba(var(--color-lightpurple), 0.15);
  overflow-x: hidden;
  transition: border 0.15s ease;
}

.fitem-container.fribbon::before {
  content: "";
  background-color: rgb(var(--color-lightpurple));
  position: absolute;
  top: 0;
  height: 10px;
  width: 100%;
}

.fitem-container > span {
  margin-top: 1.5rem;
  text-align: center;
}

/* If item uses material symbols */
.fitem-container__icon {
  background-color: rgba(var(--color-lightpurple), 0.08);
  padding: 0.5rem 1rem;
  border-radius: 12px;
  line-height: 1;
}

/* if item uses devicon icons */
.fitem-container > i {
  font-size: 4.8rem;
}

.fitem-container:hover {
  border: 1px solid rgb(var(--color-lightpurple));
}