/* Animation: Island fall */
.animateFall {
  animation-name: fall;
  animation-duration: 0.3s;
  animation-timing-function: ease;
  animation-fill-mode: forwards;
}

/* Animation: present content on pages */
.presentation {
  animation-delay: 0.15s;
  opacity: 0;
}

/* Animation: bounce off the ground */
.animateBounce {
  animation-name: bounce;
  animation-duration: 0.5s;
  animation-timing-function: ease;
  animation-fill-mode: forwards;
}

@keyframes bounce {
  0% {
    transform: translateY(-20px);
    opacity: 0;
  }
  20% {
    transform: translateY(5px);
    opacity: 0.5;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}


/* Animation: Fade in */
.animateFadeIn {
  animation-name: fadeIn;
  animation-duration: 0.3s;
  animation-timing-function: ease;
  animation-fill-mode: forwards;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  
  100% {
    opacity: 1;
  }  
}