/* cta link styling */
.cta-link {
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  padding: 1.35rem 2rem;
  border-radius: 1rem;
}

/* cta link - social */
.cta-social {
  background-color: rgba(var(--color-babypurple), 1);
}

.cta-social:hover {
  background-color: rgba(var(--color-babypurple), 0.65)
}

/* cta link - action */
.cta-action {
  background-color: rgb(var(--color-darkpurple));
}

.cta-action:hover {
  background-color: rgba(var(--color-darkpurple), 0.85);
}

.cta-link.cta-action {
  color: #fff;
}

.cta-link > span {
  color: inherit;
  margin-left: 5px;
}