/* home: hero section */
header {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f3f2ff;
  width: 100%;
  height: 390px;
}

header > h1 {
  font-size: 7.2rem;
}

header > .vector-cloud {
  position: absolute;
  opacity: 0;
}

header > .vector-cloud:first-of-type {
  top: 55%;
  left: 25%;
  animation-delay: 0.25s
}

header > .vector-cloud:last-of-type {
  top: 40%;
  right: 25%;
  animation-delay: 0.45s;
}

/* hero: floating island */
header > figure {
  position: absolute;
  width: 450px;
}

header > h1 + figure {
  bottom: -48%;
}

header > .hero-image,
figure > img {
  width: 100%;
}

figure > .vector-cloud {
  position: absolute;
  width: fit-content;
}