/* HOME: CONTENT SECTIOn */
.section-home {
  position: relative;
  background-color: #fff;
  width: 100%;
  z-index: 3;
}

.section-home > main,
.section-home + footer {
  width: 1140px;
}

.section-home > main > p:last-of-type {
  margin-bottom: 14rem;
}

/* HOME: INTRODUCTION */
.section-home__intro {
  max-width: 376px;
  margin: 0 auto 14rem;
}

.section-home__intro > h1,
.section-home__intro > h2 {
  text-align: center;
  margin: 0;
}

.section-home__intro > h2 {
  font-weight: 400;
  font-size: 1.8rem;
  line-height: 1.5;
  margin-top: 2rem;
}

/* HOME: ACTIVITIES */
.section-home article {
  position: relative;
  align-items: center;
  margin-bottom: 25rem;
  z-index: 2;
}

.section-home article:last-of-type {
  margin-bottom: 14rem;
}

.section-home article:first-of-type::after,
.section-home article:nth-of-type(2)::after {
  position: absolute;
  content: url('../../assets/images/vectors/home/dashed-connector.svg');
  top: 100%;
  z-index: 1;
}

.section-home article:first-of-type::after {
  left: 27%;
}

.section-home article:nth-of-type(2)::after {
  transform: rotateX(180deg);
  right: 24.5%;
}

.section-home article > div > h2,
.section-home article > div > p {
  margin-bottom: 1rem;
}

.section-home article:first-of-type > div:first-child,
.section-home article:nth-of-type(2) > div:last-child,
.section-home article:last-of-type > img {
  position: relative;
  flex: 0.55;
  height: 330px;
  border-radius: 1.6rem;
  overflow: hidden;
}

.section-home article > div:last-child,
.section-home article:nth-of-type(2) > div:first-child {
  flex: 0.45;
}

/* margin on first and last activity image*/
.section-home article > div:first-child,
.section-home article:last-of-type > img {
  margin-right: 6.4rem;
}

.section-home article:first-of-type > div:first-child {
  background-color: #f3f2ff;
}

/* margin on second activity image */
.section-home article:nth-of-type(2) > div:last-child {
  background-color: #ffedB9;
  margin-left: 6.4rem;
}

/* activity images */
.section-home article > div:first-child > img,
.section-home article:nth-of-type(2) > div:last-child > img {
  position: absolute;
  width: 100%;
  top: 50px;
  left: 50px;
}

.section-home article:last-of-type > div:first-child > img {
  width: 100%;
  object-fit: contain;
  object-position: center;
}

/* activity descriptions */
.section-home article span {
  display: inline-block;
  font-weight: 600;
  margin-left: 4rem;
}

.section-home article p {
  font-size: 1.6rem;
}

.article-icon__container {
  width: fit-content;
}

.article-icon {
  position: relative;
  display: inline-block;
  background-color: #debaba;
  height: 10px;
  width: 10px;
  margin: 0;
  border-radius: 50%;
}

.article-icon::before,
.article-icon::after {
  content: "";
  position: absolute;
  height: inherit;
  width: inherit;
  border-radius: inherit;
}

.article-icon::before {
  background-color: #f4e1e1;
  left: 1.4rem;
}

.article-icon::after {
  background-color: #fff2f2;
  left: 2.8rem;
}

/* FUN FACTS: WRAPPER */
.facts__wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 992px;
  margin: 0 auto 4rem;
}

/* MEDIA QUERIES */
@media (min-width: 1200px) {
  .section-home article:nth-of-type(2)::after {
    right: 22.5%;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .section-home > main,
  .section-home + footer {
    width: 100%;
    max-width: 992px;
  }

  .section-home article:nth-of-type(2)::after {
    right: 14.5%;
  }
  
}

@media (min-width: 768px) and (max-width: 991px) {
  /* remove dashed line on mobile */
  .section-home article:first-of-type::after {
    left: 8.5%;
  }

  .section-home article:nth-of-type(2)::after {
    right: 14.5%;
  }

  .section-home article:first-of-type > div:first-child,
  .section-home article:nth-of-type(2) > div:last-child,
  .section-home article:last-of-type > img {
    flex: 0.8;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .section-home > main,
  .section-home + footer {
    width: 100%;
    max-width: 767px;
  }

  .section-home article {
    margin-bottom: 16rem;
  }
  
  /* remove dashed line on mobile */
   .section-home article::after {
    display: none;
  }

  .section-home .article-icon__container {
    margin: 0 auto 3.6rem;
  }

  .section-home article:first-of-type > div:first-child,
  .section-home article:nth-of-type(2) > div:last-child,
  .section-home article:last-of-type > img {
    flex: 1 0 100%;
    margin: 0;
    margin-bottom: 4.8rem;
  }

  /* Reverse order of "Product" activity items */
  .section-home article:nth-of-type(2) > div:first-child {
    order: 2;
  }

  .section-home article:nth-of-type(2) > div:last-child {
    order: 1;
  }

  .section-home article > div:last-child,
  .section-home article:nth-of-type(2) > div:first-child {
    flex: 1;
  }

  .section-home article > div:first-child,
  .section-home article:last-of-type > img {
    margin-right: 0;
  }

  /* expand fun facts to full width */
  .facts__sticker {
    flex: 1 0 100%;
  }
}

@media (min-width: 375px) and (max-width: 575px) {
  /* SECTION HOME */
  .section-home > main,
  .section-home + footer {
    width: 100%;
    max-width: 575px;
  }

  .section-home__intro {
    margin: 0 auto 10rem;
  }

  .section-home__intro > h2 {
    margin-top: 4.8rem;
  }

  .section-home article {
    margin-bottom: 16rem;
  }

  .section-home .article-icon__container {
    margin: 0 auto 3.6rem;
  }

  .section-home article:first-of-type > div:first-child,
  .section-home article:nth-of-type(2) > div:last-child,
  .section-home article:last-of-type > img {
    flex: 1 0 100%;
    height: 200px;
    margin: 0;
    margin-bottom: 4.8rem;
  }

  /* Reverse order of "Product" activity items */
  .section-home article:nth-of-type(2) > div:first-child {
    order: 2;
  }

  .section-home article:nth-of-type(2) > div:last-child {
    order: 1;
  }

  .section-home article > div:first-child > img,
  .section-home article:nth-of-type(2) > div:last-child > img {
    top: 30px;
    left: 30px;
  }

  .section-home article > div:last-child,
  .section-home article:nth-of-type(2) > div:first-child {
    flex: 1;
  }

  .section-home article > div:first-child,
  .section-home article:last-of-type > img {
    margin-right: 0;
  }

  /* remove dashed line on mobile */
  .section-home article::after {
    display: none;
  }

  /* expand fun facts to full width */
  .facts__sticker {
    flex: 1 0 100%;
  }
}