.letterbrick__container {
  position: absolute;
  width: max-content;
  top: 20%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 3;
}

.letterbrick__container > .cta-link {
  position: absolute;
  top: 135px;
  left: 95px;
  padding: 1rem;
}

.letterbrick__container > a > span {
  font-size: 3.25rem;
  margin: 0;
}

.letterbrick-link {
  cursor: pointer;
  animation-delay: 0.7s;
  opacity: 0;
}

.letterbrick {
  position: relative;
  display: inline-block;
  background-color: rgb(var(--color-babypurple));
  font-size: 3.6rem;
  border-radius: 8px;
  padding: 1rem 2rem;
  text-align: center;
  margin: 0;
  opacity: 0;
}

.letterbrick:first-of-type {
  font-size: 4.8rem;
}

.letterbrick:nth-of-type(2) {
  left: 3.8rem;
  animation-delay: 0.1s;
}

/* 7.2rem is the height of the brick */
.letterbrick:nth-of-type(3) {
  top: calc(7.2rem + 3.8rem);
  left: -100px;
  animation-delay: 0.2s;
}

.letterbrick:last-of-type {
  top: calc(7.2rem + 3.8rem);
  left: calc(-100px + 3.8rem);
  font-size: 4.2rem;
  animation-delay: 0.3s;
}