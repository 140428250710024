.project-container:last-child {
  margin-bottom: 7.8rem;
}

.project-container__image {
  width: 100%;
  height: auto;
  border-radius: 1.6rem;
  overflow: hidden;
}

.project-container__image > img {
  width: 100%;
  height: inherit;
  object-fit: cover;
}