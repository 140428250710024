/* FOOTER */
.footer-links {
  display: flex;
  justify-content: left;
  width: fit-content;
}

.footer-links > .cta-link {
  padding: 0.75rem;
  cursor: pointer;
}

.footer-links > a > span {
  font-size: 3.25rem;
  margin: 0;
}

.footer-links > a:not(:last-of-type) {
  margin-right: 1.35rem;
}