/* margins */
.mb-l {
  margin-bottom: 6rem;
}

.mb-md {
  margin-bottom: 3.8rem;
}

.mr-l {
  margin-right: 6rem;
}

.mr-md {
  margin-right: 3.8rem;
}

.mt-l {
  margin-top: 6rem;
}

.mt-md {
  margin-top: 3.8rem;
}

/* divider */
hr {
  border: 0;
  height: 0;
  border-bottom: 1px solid rgba(var(--color-lightpurple), 0.35);
}