/* HOME: HERO SECTION */
.hero__home {
  position: relative;
  background-image: url("../../assets/images/vectors/valley-silhouette.svg"),
    linear-gradient(180deg, #a09cce, #f0f4ff);
  background-repeat: no-repeat;
  background-position: 0 150%;
  background-size: contain;
  width: 100%;
  height: 100vh;
}

/* hero images */
.hero__home > img {
  position: absolute;
  width: auto;
}

/* hero images: stars */
.hero__home > img:first-of-type {
  top: 5%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
}

/* hero images: front trees */
.hero__home > img:nth-of-type(2) {
  z-index: 2;
  bottom: -9%;
}

/* hero images: back trees */
.hero__home > img:last-of-type {
  z-index: 1;
  bottom: 0;
}

/* hero greeting  */
.hero__home-greeting {
  position: absolute;
  top: 20%;
  text-align: center;
  z-index: 2;
}

.hero__home-greeting--h1 {
  font-size: 10.2rem;
  margin-bottom: 2rem;
}

.hero__home-greeting--h2 {
  font-size: 4.8rem;
}

/* hero scroll label
.scroll-label {
  background-color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow:
  inset 0 -4px 4px rgba(var(--color-lightpurple), 0.4),
  0 4px 4px rgba(var(--color-lightpurple), 0.25);
  z-index: 2;
} */

/* hero floating island containers */
.hero__home > figure {
  width: 325px;
  z-index: 3;
  opacity: 0;
  cursor: pointer;
}

.hero__home > figure > .signpost {
  position: absolute;
  display: flex;
  width: max-content;
  background-color: rgb(var(--color-darkpurple));
  color: #fff;
  border-radius: 8px;
  font-size: 1.6rem;
  padding: 1rem 2rem;
  opacity: 0;
  transition: transform 0.4s ease, opacity 0.2s ease;
}

.hero__home > figure:not(:last-of-type) > .signpost {
  top: -20%;
  left: 40%;
}

.hero__home > figure:last-of-type > .signpost {
  top: -40%;
  left: 15%;
}

.hero__home > figure img {
  transition: transform 0.4s ease;
}

.hero__home > figure:hover > .signpost {
  transform: translateY(-65%);
  opacity: 1;
}

.hero__home > figure:hover .vector-cloud:first-of-type {
  transform: translate(-30px, -5px);
}
.hero__home > figure:hover .vector-island {
  transform: scale(1.1);
}
.hero__home > figure:hover .vector-cloud:last-of-type {
  transform: translate(30px, -5px);
}

/* floating container: Mountain island */
.hero__home > figure:first-of-type {
  top: 49%;
  left: 7%;
  animation-delay: 0.7s;
}

figure:first-of-type > .vector-cloud:first-of-type {
  top: -25%;
  left: 10%;
}

figure:first-of-type > .vector-cloud:last-of-type {
  width: 60px;
  top: -5%;
  right: 15%;
}

/* floating container: Lakeside island */
.hero__home > figure:nth-of-type(2) {
  top: 70%;
  animation-delay: 0.7s;
  /* left: 50%; */
  /* transform: translateX(-50%); */
}

figure:nth-of-type(2) > .vector-cloud:first-of-type {
  width: 60px;
  top: -15%;
  left: 30%;
}

/* floating container: Countryside island */
.hero__home > figure:last-of-type {
  top: 55%;
  right: 7%;
  animation-delay: 0.7s;
}

figure:last-of-type > .vector-cloud {
  width: 70px;
}

figure:last-of-type > .vector-cloud:first-of-type {
  top: -20%;
  left: 40%;
}

figure:last-of-type > .vector-cloud:last-of-type {
  top: -35%;
  right: 15%;
}

@keyframes fall {
  0% {
    transform: translateY(-10px);
    opacity: 0;
  }
  100% {
    transform: translateY(0%);
    opacity: 1;
  }
}

/* MEDIA QUERIES */
@media (min-width: 375px) and (max-width: 575px) {
  .hero__home {
    background-position: 0 100%;
  }
  
  .hero__home > figure {
    display: none;
  }

  /* HERO: Forest and Hill Images */
  .hero__home > img:first-of-type {
    top: 8%;
    left: 0;
    transform: translateX(0);
    width: inherit;
  }

  .hero__home > img:nth-of-type(2) {
    bottom: 0;
    width: inherit;
  }

  .hero__home > img:last-of-type {
    bottom: 0;
    width: inherit;
  }

  /* LETTER BRICKS */
  .letterbrick__container {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: max-content;
  }

  .letterbrick {
    font-size: 2.4rem;
  }

  .letterbrick:first-of-type {
    font-size: 3.2rem;
    top: -100px;
    left: 60px;  
  }

  .letterbrick:nth-of-type(2) {
    top: -20px;
    left: -45px;
  }

  .letterbrick:nth-of-type(3) {
    top: -20px;
    left: -20px;
  }

  .letterbrick:last-of-type {
    font-size: 2.8rem;
    top: 60px;
    left: -95px;    
  }

  .letterbrick__container > .cta-link {
    top: 75px;
    left: 118px;
    padding: 0.5rem;
  }
}

@media (min-width: 576px) and (max-width: 991px) {
  .hero__home {
    background-position: 0 100%;
  }

  /* HERO: Forest and Hills Images */
  .hero__home > img:first-of-type {
    top: 8%;
    left: 0;
    transform: translateX(0);
    width: inherit;
  }

  .hero__home > img:nth-of-type(2) {
    bottom: 0;
    width: inherit;
  }

  .hero__home > img:last-of-type {
    bottom: 0;
    width: inherit;
  } 

  /* HERO: Island images */
  .hero__home > figure {
    width: 180px; 
  }

  .hero__home > figure:last-of-type {
    top: 35%;
  }

  .hero__home > figure:nth-of-type(2) {
    top: 75%;
    left: 50%;
  }

  /* HERO: Island Cloud images */
  .hero__home > figure > .vector-cloud {
    width: 30%;
  }

  figure:last-of-type > .vector-cloud:first-of-type {
    left: 20%;
  }

  /* HERO: Island Signposts */
  .hero__home > figure:not(:last-of-type) > .signpost {
    top: -30%;
    left: 40%;
  }

  .hero__home > figure:last-of-type > .signpost {
    top: -70%;
    left: 15%;
  }
  
  /* LETTER BRICKS */
  .letterbrick__container {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: max-content;
  }

  .letterbrick:first-of-type {
    font-size: 3.8rem;
    top: -150px;
    left: 60px;  
  }

  .letterbrick:nth-of-type(2) {
    font-size: 2.4rem;
    top: -50px;
    left: -45px;
  }

  .letterbrick:nth-of-type(3) {
    font-size: 2.8rem;
    top: -50px;
    left: -20px;
  }

  .letterbrick:last-of-type {
    font-size: 3.2rem;
    top: 50px;
    left: -100px;
  }

  .letterbrick__container > .cta-link {
    top: 70px;
    left: 140px;
    padding: 0.5rem;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .hero__home > figure {
    width: 220px; 
  }

  .hero__home > figure:last-of-type > .signpost {
    top: -55%;
    left: 0;
  }
}

@media (min-width: 992px) and (max-width: 1400px) {
  .hero__home {
    background-position: 0 100%;
  }

  .hero__home > figure {
    width: 250px; 
  }

  .hero__home > figure:last-of-type > .signpost {
    top: -50%;
    left: 0;
  }

  .hero__home > figure:not(:last-of-type) > .signpost {
    top: -30%;
    left: 40%;
  }

  figure:last-of-type > .vector-cloud:first-of-type {
    left: 20%;
  }

  /* HERO: Forest and Hills Images */
  .hero__home > img:first-of-type {
    top: 5%;
    left: 50%;
    transform: translateX(-50%);
    width: inherit;
  }

  .hero__home > img:nth-of-type(2) {
    bottom: 0;
    width: inherit;
  }

  .hero__home > img:last-of-type {
    bottom: 0%;
    width: inherit;
  } 
}