main {
  position: relative;
  background-color: #fff;
  width: 992px;
  margin: 0 auto;
  padding: 150px 2.4rem 3.8rem 2.4rem;
  min-height: 100vh;
  z-index: 4
}

footer {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 992px;
  padding: 0 2.4rem;
  margin: 0 auto 6rem;
}

/* site content wrapper */
.content {
  width: 100%;
  min-height: 100vh;
  overflow-x: clip;
}

.flex-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

/* MEDIA QUERIES */
@media (min-width: 768px) and (max-width: 991px) {
  main, footer {
    max-width: 768px;
  }

  /* navbar logo */
  nav > img {
    width: 45px;
    height: auto;
  }

  /* page header: h1 title */
  header > h1 {
    font-size: 6rem;
  }

  /* page header: cloud images */
  header > .vector-cloud:first-of-type {
    left: 10%
  }

  header > .vector-cloud:last-of-type {
    right: 10%;
  }

  /* TOOLS: Tech Item container */
  .fitem-container {
    flex: 0 0 160px;
  }

  /* --------- ABOUT ---------- */
  /* ABOUT: IMAGE DIVIDER */
  .flex-container > .image__divider {
    flex: 0 0 48%;
  }

  .flex-container > .image__divider:not(:last-child) {
    margin-right: 0;
  }

  /* ABOUT: GALLERY FRAME */
  .gallery__wrapper {
    gap: 1rem;
  }

  .gallery__frame:first-of-type {
    grid-column: 1 / 2;
  }
  
  .gallery__frame:nth-of-type(2) {
    grid-column: 3 / 4;
  }
  
  .gallery__frame:nth-of-type(3) {
    grid-column: 1 / 2;
  }
  
  .gallery__frame:last-of-type {
    grid-column: 3 / 4;
  }

  .gallery__frame::after {
    opacity: 1;
  }
} 

@media (min-width: 576px) and (max-width: 767px) { 
  /* MAIN CONTAINER, Footer */
  main {
    width: 100%;
    max-width: 575px;
  }

  footer {
    max-width: 575px;
    font-size: 1.8rem;
  }

  /* navbar logo */
  nav > img {
    width: 45px;
    height: auto;
  }

  /* page header: h1 title */
  header > h1 {
    font-size: 5.6rem;
  }

  /* all header images: island width */
  header > figure {
    width: 400px;
  }

  /* page header: island image */
  header > h1 + figure {
    bottom: -42.4%;
  }

  /* page header: cloud images */
  header > .vector-cloud {
    width: 70px;
  }
  header > .vector-cloud:first-of-type {
    left: 10%
  }

  header > .vector-cloud:last-of-type {
    right: 10%;
  }

  /* TOOLS: Tech Item container */
  main .flex-container:last-child {
    margin-top: 0;
    margin-bottom: 3.8rem;
  }

  .fitem-container {
    flex: 0 0 50%;    
  }

  /* ABOUT: IMAGE DIVIDER */
  .flex-container > .image__divider {
    flex: 0 0 48%;
  }

  .flex-container > .image__divider:not(:last-child) {
    margin-right: 0;
  }

  /* --------- ABOUT ---------- */
  
  /* ABOUT: AUTHOR */
  .author__photo,
  .author__intro {
    flex: max-content;
  }

  .author__photo {
    margin: 0;
    border-radius: 2rem;
    margin-bottom: 3.8rem;
  }

  .author__bio {
    margin-right: 1.6rem;
  }

  /* ABOUT: GALLERY FRAME */
  .gallery__frame:first-of-type {
    grid-column: 1 / -1;
  }
  
  .gallery__frame:nth-of-type(2) {
    grid-column: 1 / -1;
  }
  
  .gallery__frame:nth-of-type(3) {
    grid-column: 1 / -1;
  }
  
  .gallery__frame:last-of-type {
    grid-column: 1 / -1;
  }

  .gallery__frame::after {
    opacity: 1;
  }
}

@media (min-width: 375px) and (max-width: 575px) { 
  /* NAVBAR */
  nav {
    justify-content: center;
  }

  /* NAVBAR: Logo */
  nav > img {
    display: none;
  }
 
  /* PAGE HEADER: Header Title */
  header > h1 {
    font-size: 4.8rem;
  }

  /* PAGE HEADER: Island */
  header > figure {
    width: 325px;
  }

  /* PAGE HEAEDER: Island Bottom Positioning*/
  header > h1 + figure {
    bottom: -34.7%;
  }

  /* PAGE HEAEDER: Clouds */
  header > .vector-cloud {
    width: 60px;
  }

  header > .vector-cloud:first-of-type {
    top: 65%;
    left: 25%
  }

  header > .vector-cloud:last-of-type {
    top: 55%;
    right: 25%;
  }

  /* MAIN CONTAINER, Footer */
  main {
    width: 100%;
    max-width: 575px;
    padding: 120px 2.4rem 3.8rem;
  }

  footer {
    width: 100%;
    max-width: 575px;
    font-size: 1.65rem;
  }

  /* MAIN CONTAINER: Page Title */
  main h1 {
    font-size: 3.2rem;
    text-align: center;
    margin-bottom: 4.8rem;
  }

  /* MAIN CONTAINER: Page Subtitle */
  main h2 {
    font-size: 2.25rem;
  }

  /* TOOLS: Tech Item container */
  main .flex-container:last-child {
    margin-top: 0;
    margin-bottom: 3.8rem;
  }

  .fitem-container {
    flex: 0 0 50%;    
  }

  /* --------- ABOUT ---------- */

  /* ABOUT: AUTHOR */
  .author__photo,
  .author__intro {
    flex: max-content;
  }
  
  .author__photo {
    margin: 0;
    border-radius: 2rem;
    margin-bottom: 3.8rem;
  }

  .author__intro > h2 {
    text-align: center;
  }

  .author__bio,
  .author__contact {
    flex: 1 0 100%;
  }

  .author__bio {
    margin-bottom: 1.6rem;
  }


  /* ABOUT: GALLERY FRAME */
  .gallery__frame:first-of-type {
    grid-column: 1 / -1;
  }
  
  .gallery__frame:nth-of-type(2) {
    grid-column: 1 / -1;
  }
  
  .gallery__frame:nth-of-type(3) {
    grid-column: 1 / -1;
  }
  
  .gallery__frame:last-of-type {
    grid-column: 1 / -1;
  }

  .gallery__frame::after {
    opacity: 1;
  }

  /* ABOUT: IMAGE DIVIDER */
  .flex-container > .image__divider {
    flex: 0 0 48%;
  }

  .flex-container > .image__divider:not(:last-child) {
    margin-right: 0;
  }
}