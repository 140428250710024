nav {
  position: fixed;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 70px;
  border-bottom: 1px solid transparent;
  padding: 0 4rem;
  z-index: 10;
  transition: background-color 0.15s ease;
}

nav.isScrolling {
  border-bottom: 1px solid rgba(var(--color-lightpurple), 0.15);
  background-color: rgba(255, 255, 255, 0.75);
  backdrop-filter: blur(1.6rem);
}

.navlinks-container {
  display: flex;
  width: 350px;
  justify-content: space-between;
  align-items: center;
}

.navlinks-container > a {
  padding: 0.35rem 1.7rem;
  border-radius: 5px;
}

.navlinks-container > a:hover {
  background-color: rgba(var(--color-lightgray));
}

.navlinks-container > a.active,
.navlinks-container > a:active {
  background-color: rgba(255, 255, 255);
}

nav.isScrolling > .navlinks-container > a.active,
nav.isScrolling > .navlinks-container > a:active {
  background-color: rgba(var(--color-babypurple));
  color: rgba(var(--color-darkpurple));
}