.image__divider {
  background-color: rgb(var(--color-babypurple));
  width: 100%;
  padding-top: 3rem;
  border-radius: 1rem;
  overflow: hidden;
}

.image__divider > img {
  display: block;
  width: min-content;
  margin: 0 auto;
}

/* Image divider in a flex box */
.flex-container > .image__divider {
  width: auto;
  flex: 0.25;
}

.flex-container > .image__divider:not(:last-child) {
  margin-right: 16px;
}