@import url('https://fonts.googleapis.com/css2?family=Inconsolata:wght@300;400;600;700&display=swap');

:root {
  --color-darkpurple: 82, 78, 99;
  --color-lightpurple: 146, 141, 171;
  --color-babypurple: 243, 242, 255;
  --color-gray: 85, 85, 85;
  --color-lightgray: 226, 226, 226;
}

*,
*::before,
*::after {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
}

body {
  background-color: #fff;
  font-family: Inconsolata, system-ui, sans-serif;
  color: rgb(var(--color-darkpurple));
  line-height: 1.45;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a:link,
a:visited {
  text-decoration: none;
  font-size: 1.6rem;
  color: inherit;
}

a:hover {
  transition:
    background-color 0.15s ease,
    color 0.15s ease;
}

a.notfound__link {
  padding: 0.75rem 1.5rem;
  color: rgb(var(--color-lightpurple));
  background-color: rgb(var(--color-babypurple));
  font-weight: 600;
  border-radius: 8px;
}