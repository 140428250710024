/* AUTHOR BIO */
.author > p:last-child {
  margin-bottom: 3.8rem;
}

/* Author: avatar and Introduction */
.author__photo {
  background-color: rgb(var(--color-babypurple));
  flex: 0.3 min-content;
  border-radius: 1rem;
  overflow: hidden;
  animation-delay: 0.1s;
  opacity: 0;
}

.author__photo > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.author__intro {
  flex: 0.7;
  animation-delay: 0.2s;
}

.author__intro > h2 {
  animation-delay: 0.15s;
  opacity: 0;
}

.author__intro > p {
  animation-delay: 0.2s;
  opacity: 0;
}

.author__intro > p:last-child {
  margin: 0;
}

/* Author: Contact buttons */
.author__bio {
  flex: 0.3;
}

.author__bio > a {
  width: 100%;
}

.author__contact {
  display: flex;
  flex: 0.7;
}

.author__contact > a {
  padding: 1rem 1.5rem;
}

.author__contact > a > i {
  font-size: 2.2rem;
}

.author__contact > a:last-child {
  flex: 1;
}

.author__contact > a:not(:last-child) {
  margin-right: 16px;
}

/* GALLERY */
.gallery__wrapper {
  display: grid;
  grid-template-rows: repeat(2, 370px);
  gap: 3.8rem;
  margin-bottom: 7.8rem;
}

/* TO DO: LEISURE PHOTOS WITH LABELS */
.gallery__frame {
  position: relative;
  background-color: rgb(var(--color-babypurple));
  border-radius: 1rem;
  z-index: 1;
  overflow: hidden;
}

.gallery__frame:first-of-type {
  grid-column: 1 / span 2;
}

.gallery__frame:nth-of-type(2) {
  grid-column: 3 / 4;
}

.gallery__frame:nth-of-type(3) {
  grid-column: 1 / 2;
}

.gallery__frame:last-of-type {
  grid-column: 2 / 4;
}

.gallery__frame::after {
  background-color: #fff;
  position: absolute;
  bottom: 2rem;
  right: 2rem;
  padding: 8px 1.4rem;
  border-radius: 8px;
  font-size: 1.6rem;
  color: inherit;
  z-index: 2;
  opacity: 0;
  transform: translateY(10px);
  transition:
    transform 0.15s ease,
    opacity 0.15s ease;
}

.gallery__frame:first-of-type::after {
  content: "Zlatar, Serbia";
}

.gallery__frame:nth-of-type(2)::after {
  content: "Mont-Saint-Michel, France";
}

.gallery__frame:nth-of-type(3)::after {
  content: "Ronda, Spain";
}

.gallery__frame:last-of-type::after {
  content: "Saint-Malo, France";
}

.gallery__frame:hover::after {
  opacity: 1;
  transform: translateY(0);
}

.gallery__frame > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}